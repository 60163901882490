import React, { useState, useEffect, useContext } from 'react'
import FsLightbox from 'fslightbox-react';
import { UserContext } from '../layouts/UserContext';
import './demo.scss'
// import LocalSession from './LocalSession'

function SubscribeTwo({location, errors}) {

  // LightBox Toggle State
  const [us, setUS] = useState(false)
  const [ca, setCA] = useState(false)

  const usFrame = (
    <div className="modal-contain">
      <iframe
        className='icontain-modal'
        src="https://meetings.hubspot.com/trish-cook/workhub-demo"
        width="1000px"
        height="1000px"
        scrolling="no"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </div>
  )

  const caFrame = (
    <div className="modal-contain">
      <iframe
        className="icontain-modal"
        src="https://meetings.hubspot.com/trish-cook/workhub-demo"
        width="1000px"
        height="1000px"
        scrolling="no"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </div>
  )

  return (
    <div>
      <section className="payment_subscribe_area">
        <div className="container">
          <div className="row">
            <div className="payment_subscribe_info ">
              <div className="payment_subscribe_content col-lg-6">
                <h2>Still have questions?</h2>
                <p>Let our knowledgeable sales team give you a full product tour and answer any specific questions you may have.</p>
              </div>
              <div className="payment_subscribe_content col-lg-4 offset-lg-2" style={{ textAlign: 'center' }}>

                {/* START OLD BTN RENDER LOGIC */}

                {/* <h2>Book A Demo</h2> */}

                {/* US TOGGLER */}

                {/* {location === 'US' ? (<><button type="button"
                  onClick={() => setUS(!us)}
                  onKeyDown={() => setUS(!us)}
                  className="btn_hover btn_four demo_us">
                  <img src="/app-icons/united-states.svg" height="24px" width="24px" alt="" /> US</button>
                  <FsLightbox
                    toggler={us}
                    sources={[usFrame]}
                  /></>) : null} */}

                {/* CA TOGGLER */}

                {/* {location === 'CA' ? <><button type="button"
                  onClick={() => setCA(!ca)}
                  onKeyDown={() => setCA(!ca)}
                  className="btn_hover btn_four demo_canada">
                  <img src="/app-icons/canada.svg" height="24px" width="24px" alt="" /> Canada</button>
                  <FsLightbox
                    toggler={ca}
                    sources={[caFrame]}
                  /></> : null} */}

                {/* API Fails */}

                {/* {location === undefined ? (<><button type="button"
                  onClick={() => setUS(!us)}
                  onKeyDown={() => setUS(!us)}
                  className="btn_hover btn_four demo_us">
                  <img src="/app-icons/united-states.svg" height="24px" width="24px" alt="" /> US</button>
                  <FsLightbox
                    toggler={us}
                    sources={[usFrame]}
                  />
                  <button type="button"
                    onClick={() => setCA(!ca)}
                    onKeyDown={() => setCA(!ca)}
                    className="btn_hover btn_four demo_canada">
                    <img src="/app-icons/canada.svg" height="24px" width="24px" alt="" /> Canada</button>
                  <FsLightbox
                    toggler={ca}
                    sources={[caFrame]} />
                </>) : null} */}

                {/* International */}

                {/* {location !== 'US' && location !== 'CA' && location !== undefined ? (<><button type="button"
                  onClick={() => setUS(!us)}
                  onKeyDown={() => setUS(!us)}
                  className="btn_hover btn_four demo_us">
                  <img src="/app-icons/united-states.svg" height="24px" width="24px" alt="" /> US</button>
                  <FsLightbox
                    toggler={us}
                    sources={[usFrame]}
                  /></>) : null} */}

                  {/* END BTN RENDER LOGIC */}

                  <><button type="button"
                  onClick={() => setCA(!ca)}
                  onKeyDown={() => setCA(!ca)}
                  className="btn_hover btn_four demo_canada">
                   Book a Demo</button>
                  <FsLightbox
                    toggler={ca}
                    sources={[caFrame]}
                  />
                  </>


              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SubscribeTwo
